import { Box, Typography } from "@mui/material";
import theme from "../../util/theme";
import Button from "../common/Button/Button";


export default function Hero() {
  const styles = {
    container: {
      display: "flex",
      height: "100vh",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop:"40px",
      padding: "20px",
      "& > *": {
        margin: "20px !important",
      },
    },
    heroHeading: {
      fontSize: {
        xs: 32,
        sm: 60, 
        md: 80, 
        lg: 110, 
        xl: 110, 
      },
      textAlign: "center",
      "@media (min-width: 600px)": {
        textAlign: "left",
      },
    },
    heroTagline: {
      fontSize: {
        xs: 20,
        sm: 25,
        md: 30,
        lg: 35,
        xl: 40,
      },
      textAlign: "center",
      margin: "auto",
      marginTop: "20px",
    },
  };
  return (
    <Box sx={styles.container}>
      <Typography variant="h3" sx={styles.heroHeading}>
        Find{" "}
        <span style={{ color: theme.palette.primary.main }}>
          top candidates
        </span>
        <br />
        in one click with AI.
      </Typography>
      <Typography variant="h4" sx={styles.heroTagline}>
        Paste your job description, get links to the{" "}
        <span style={{ color: theme.palette.primary.main }}>
          top 10 <br /> fitting candidates{" "}
        </span>{" "}
        and their LinkedIn profiles instantly. <br /> Our AI streamlines your
        search.
      </Typography>
      <Button variant="contained">See how it works</Button>
    </Box>
  );
}
