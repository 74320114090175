export const AUTH_ACTIONS = {
  REGISTERING_USER: "REGISTERING_USER",
  REGISTERED_USER: "REGISTERED_USER",
  REGISTERING_USER_FAILED: "REGISTERING_USER_FAILED",
  VERIFYING_EMAIL: "VERIFYING_EMAIL",
  VERIFIED_EMAIL: "VERIFIED_EMAIL",
  VERIFIED_EMAIL_FAILED: "VERIFIED_EMAIL_FAILED",
  LOGIN_USER: "LOGIN_USER",
  LOGGEDIN_USER: "LOGGEDIN_USER",
  LOGIN_USER_FAILED: "LOGIN_USER_FAILED",
  FETCHING_USER: "FETCHING_USER",
  FETCHED_USER: "FETCHED_USER",
  FETCHING_USER_FAILED: "FETCHING_USER_FAILED",
  TAKING_CSRF: "TAKING_CSRF",
  TAKEN_CSRF: "TAKEN_CSRF",
  CSRF_FAILED: "CSRF_FAILED",
  SENDING_INVITE: "SENDING_INVITE",
  SEND_INVITE: "SEND_INVITE",
  SENDING_INVITE_FAILED: "SENDING_INVITE_FAILED",
  JOINING_MEMBER: "JOINING_MEMBER",
  JOINED_MEMBER: "JOINED_MEMBER",
  JOINING_MEMBER_FAILED: "JOINING_MEMBER_FAILED",
  GETTING_MEMBERS: "GETTING_MEMBERS",
  GET_MEMBERS: "GET_MEMBERS",
  GETTING_MEMBERS_FAILED: "GETTING_MEMBERS_FAILED",
  CREATING_QUERY: "CREATING_QUERY",
  CREATED_QUERY: "CREATED_QUERY",
  CREATING_QUERY_FAILED: "CREATING_QUERY_FAILED",
  GETTING_ALL_RESPONSES: "GETTING_ALL_RESPONSES",
  GET_ALL_RESPONSES: "GET_ALL_RESPONSES",
  GET_ALL_RESPONSES_FAILED: "GET_ALL_RESPONSES_FAILED",
  GETTING_ALL_USERS: "GETTING_ALL_USERS",
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ALL_USERS_FAILED: "GET_ALL_USERS_FAILED",
  GETTING_STATUS: "GETTING_STATUS",
  GET_STATUS: "GET_STATUS",
  GET_STATUS_FAILED: "GET_STATUS_FAILED",
  USER_QUERYING: "USER_QUERYING",
  USER_QUERY: "USER_QUERY",
  USER_QUERY_FAILED: "USER_QUERY_FAILED",
  CREATING_SESSION: "CREATING_SESSION",
  CREATED_SESSION: "CREATED_SESSION",
  CREATED_SESSION_FAILED: "CREATED_SESSION_FAILED",
  CANCELING_SUBSCRIPTION: "CANCELING_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION_FAILED: "CANCEL_SUBSCRIPTION_FAILED",
};

export const AUTH_END_POINT = {
  REGISTER: "user/sign-up",
  LOGIN: "user/login",
  VERFIY: "user/verify",
  SENDINVITE: "user/send-invite",
  JOINMEMBER: "user/join-member",
  GET_MEMBERS: "user/get-all-member",
  CREAETQUERY: "user/query",
  USER: "user/me",
  GETALLRESPONSE: "user/get-all-query",
  CSRF: "csrftoken",
  ALL: "user/total-users",
  STATUS: "user/get-status",
  USERQUERY: "user/user-query",
  PLAN: "stripe/create-subscription-checkout-session",
  CANCEL: "stripe/cancel-subscription",
};
