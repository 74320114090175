import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import theme from "../../../util/theme";

interface Props {
  variant?: "outlined" | "filled" | "standard";
  label: string;
  fullWidth?: boolean;
  placeholder?: string;
}

export default function TextField({
  variant = "outlined",
  label,
  fullWidth,
  placeholder,
  ...props
}: Props | TextFieldProps) {
  const style = {
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
      borderColor: variant === "outlined" && theme.palette.secondary.main,
      backgroundColor: variant === "outlined" && theme.palette.secondary.main,
    },
  };

  return (
    <MuiTextField
      fullWidth={fullWidth}
      variant={variant}
      label={label}
      placeholder={placeholder}
      sx={style}
      {...props}
    />
  );
}
