import { GMAIL_ACTIONS } from "./gmail.constant";

import { AddingUser, getAllEmailsSerivce } from "./gmail.service";

export const AuthorizeEMail = (email) => {
  return async (dispatch) => {
    dispatch({ type: GMAIL_ACTIONS.ADDING_USER });
    try {
      const payload = {
        email: email,
      };

      console.log("payload");
      await AddingUser(payload);
      return dispatch({ type: GMAIL_ACTIONS.ADDED_USER });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: GMAIL_ACTIONS.ADDED_USER_FAILED,
        error: error,
      });
    }
  };
};

export const getAllEmails = () => {
  return async (dispatch) => {
    dispatch({ type: GMAIL_ACTIONS.FETCHING_EMAILS });
    try {
      const emails = await getAllEmailsSerivce();
      console.log(emails);
      return dispatch({ type: GMAIL_ACTIONS.FETCHED_EMAILS, payload: emails });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: GMAIL_ACTIONS.FETCHED_EMAILS_FAILED,
        error: error,
      });
    }
  };
};
