import { GMAIL_ACTIONS } from "./gmail.constant";

const INITIAL_STATE = {
  CSRF: "",
  showAlert: false,
  userId: null,
  firstName: "",
  lastName: "",
  email: "",
  isLoggedIn: false,
  loading: false,
  emails: [],
};

export const gmailReducer = (state, action) => {
  switch (action.type) {
    case GMAIL_ACTIONS.ADDING_USER:
      return { ...state, loading: true };

    case GMAIL_ACTIONS.ADDED_USER:
      return { ...state, loading: false };

    case GMAIL_ACTIONS.ADDED_USER_FAILED:
      return { ...state, loading: false, error: "something went wrong" };

    case GMAIL_ACTIONS.FETCHING_EMAILS:
      return { ...state, loading: true };

    case GMAIL_ACTIONS.FETCHED_EMAILS:
      return { ...state, loading: false, emails: action.payload };
    case GMAIL_ACTIONS.FETCHED_EMAILS:
      return { ...state, loading: false, error: "something went wrong" };

    default:
      return INITIAL_STATE;
  }
};

//  gmailReducer;
