import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import { CircularProgress, Container, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import Select from "@mui/material/Select";
import { sendingInvite, getMembers } from "../../../app/auth/auth.action";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const styles = {
  container: {
    borderRadius: "6px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 12px",
    gap: "8px",
    height: "40px",
    width: "158px",
    backgroundColor: "rgba(6, 96, 234, 0.07999999821186066)",
  },
  text: {
    textAlign: "left",
    verticalAlign: "top",
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "24%",
    color: "#0760ea",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    pt: "24px",
    pb: "24px",
    pr: "24px",
    pl: "24px",
    borderRadius: "12px",
    border: "0.50px #E2E8F0 solid",
  },
};

const BasicTable = ({
  loading,
  sendingInvite,
  getMembers,
  organizationId,
  members,
}) => {
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };
  const handleChange = (event) => {
    setRole(event.target.value);
  };
  const submit = () => {
    sendingInvite(email, role);
  };
  React.useEffect(() => {
    getMembers(organizationId);
  }, []);
  console.log(members);

  return (
    <Container>
      <Button
        style={{ float: "right", marginTop: "12px", marginBottom: "20px" }}
        variant="contained"
        onClick={handleOpen}
      >
        Invite Member
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add your Team Member
          </Typography>

          <FormControl fullWidth variant="filled">
            <InputLabel
              style={{ display: "none" }}
              htmlFor="outlined-adornment-amount"
            >
              Email
            </InputLabel>
            <OutlinedInput
              id="email"
              onChange={handleInputChange}
              value={email}
              variant="outlined"
              fullWidth
              required
              type="email"
              placeholder="email address"
              style={{ borderRadius: "10px" }}
            />
          </FormControl>
          <br></br>
          <br></br>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Role"
              onChange={handleChange}
            >
              <MenuItem value={"member"}>Member</MenuItem>
              <MenuItem value={"Admin"}>Admin</MenuItem>
            </Select>
          </FormControl>
          <br></br>
          <br></br>

          <Button onClick={submit} variant="contained">
            {loading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: grey[500],
                }}
              />
            ) : (
              "Send Invite"
            )}
          </Button>
        </Box>
      </Modal>

      <Grid mb={2} container>
        <Grid item xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>status</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      {row.fullName ? "Joined" : "not Joined"}
                    </TableCell>
                    <TableCell>{row.Role}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <br></br>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationId: state.auth.organizationId,
    members: state.auth.members,
  };
};
const mapDispatchToProps = (dispatch) => ({
  sendingInvite: (email, role) => {
    dispatch(sendingInvite(email, role));
  },
  getMembers: (organizationId) => {
    dispatch(getMembers(organizationId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicTable);
