import { AUTH_ACTIONS } from "./action.constant";

const INITIAL_STATE = {
  CSRF: "",
  showAlert: false,
  userId: null,
  fullName: null,
  email: "",
  isLoggedIn: false,
  loading: false,
  organizationId: "",
  role: "",
  success: false,
  customerId: "",
  paymentStatus: "",
  url: "",
  subscriptionId: "",
  totalUser: [],
  queryCount: 0,
  totalcount: "",
  userQueries: [],
  realQuery: [],
  queries: [],
};

const authReducer = (state, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.REGISTERING_USER:
      return { ...state, loading: true };
    case AUTH_ACTIONS.REGISTERED_USER:
      return { ...state, loading: false };
    case AUTH_ACTIONS.VERIFYING_EMAIL:
      return { ...state, loading: true };
    case AUTH_ACTIONS.VERIFIED_EMAIL:
      return { ...state, loading: false };
    case AUTH_ACTIONS.VERIFIED_EMAIL_FAILED:
      return { ...state, loading: false };
    case AUTH_ACTIONS.REGISTERING_USER_FAILED:
      return { ...state, loading: false, error: action.error };
    case AUTH_ACTIONS.LOGIN_USER:
      return { ...state, loading: true };
    case AUTH_ACTIONS.LOGGEDIN_USER:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        realQuery: [],
        fullName: action.payload.fullName,
        organizationId: action.payload.organizationId,
        role: action.payload.Role,
        paymentStatus: action.payload.paymentStatus,
        customerId: action.payload.customerId,
        subscriptionId: action.payload.subscriptionId,
      };
    case AUTH_ACTIONS.FETCHING_USER:
      return { ...state, loading: true };
    case AUTH_ACTIONS.FETCHED_USER:
      return {
        ...state,
        loading: false,
        fullName: action.payload.fullName,
        organizationId: action.payload.organizationId,
        role: action.payload.Role,
        queryCount: action.payload.QueryCount,
        paymentStatus: action.payload.paymentStatus,
        customerId: action.payload.customerId,
        subscriptionId: action.payload.subscriptionId,
        realQuery: [],
      };
    case AUTH_ACTIONS.GETTING_ALL_USERS:
      return { ...state, loading: true };
    case AUTH_ACTIONS.GET_ALL_USERS:
      return {
        ...state,
        loading: false,
        totalcount: action.payload.count,
        totalUser: action.payload.users,
      };
    case AUTH_ACTIONS.GETTING_STATUS:
      return { ...state, loading: true };
    case AUTH_ACTIONS.GET_STATUS:
      return { ...state, loading: false, userQueries: action.payload };
    case AUTH_ACTIONS.GET_STATUS_FAILED:
      return { ...state, loading: false };
    case AUTH_ACTIONS.SENDING_INVITE:
      return { ...state, loading: true };

    case AUTH_ACTIONS.SEND_INVITE:
      return { ...state, loading: false };
    case AUTH_ACTIONS.SENDING_INVITE_FAILED:
      return { ...state, loading: false };
    case AUTH_ACTIONS.GETTING_MEMBERS:
      return { ...state, loading: true };
    case AUTH_ACTIONS.GET_MEMBERS:
      return { ...state, loading: false, members: action.payload };
    case AUTH_ACTIONS.GETTING_MEMBERS_FAILED:
      return { ...state, loading: false, error: "" };
    case AUTH_ACTIONS.JOINING_MEMBER:
      return { ...state, loading: true };
    case AUTH_ACTIONS.JOINED_MEMBER:
      return { ...state, loading: false };
    case AUTH_ACTIONS.JOINING_MEMBER_FAILED:
      return { ...state, loading: false };
    case AUTH_ACTIONS.CREATING_QUERY:
      return { ...state, loading: true, success: false };
    case AUTH_ACTIONS.CREATED_QUERY:
      return {
        ...state,
        loading: false,
        success: true,
        queries: action.payload,
      };
    case AUTH_ACTIONS.USER_QUERYING:
      return { ...state, loading: true };
    case AUTH_ACTIONS.USER_QUERY:
      return { ...state, loading: false, realQuery: action.payload };
    case AUTH_ACTIONS.USER_QUERY_FAILED:
      return { ...state, loading: false };
    case AUTH_ACTIONS.CREATING_QUERY_FAILED:
      return { ...state, loading: false, error: "", success: false };
    case AUTH_ACTIONS.GETTING_ALL_RESPONSES:
      return { ...state, loading: true };
    case AUTH_ACTIONS.GET_ALL_RESPONSES:
      return { ...state, loading: false, queries: action.payload };
    case AUTH_ACTIONS.GET_ALL_RESPONSES_FAILED:
      return { ...state, loading: false, error: "" };
    case AUTH_ACTIONS.LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        showAlert: true,
        error: action.error,
      };
    case AUTH_ACTIONS.CREATING_SESSION:
      return { ...state, loading: true };
    case AUTH_ACTIONS.CREATED_SESSION:
      return { ...state, loading: false, url: action.payload };
    case AUTH_ACTIONS.CREATED_SESSION_FAILED:
      return { ...state, loading: false };
    case AUTH_ACTIONS.CANCELING_SUBSCRIPTION:
      return { ...state, loading: true };
    case AUTH_ACTIONS.CANCEL_SUBSCRIPTION:
      return { ...state, loading: false };
    default:
      return INITIAL_STATE;
  }
};

export default authReducer;
