import React from "react";
import Icon from "../../assests/Mail sent-rafiki.png";
import { Grid, Box, Typography, Button } from "@mui/material";

const index = () => {
  return (
    <>
      <Box sx={{ mt: "100px" }}>
        <Typography
          color="black"
          variant="h1"
          fontWeight="600"
          fontSize="35px"
          style={{ textAlign: "center" }}
          sx={{
            mr: 1,
          }}
        >
          Verify Email
        </Typography>
        <img
          src={Icon}
          style={{
            width: "22%",
            display: "flex",
            margin: "auto",
          }}
          alt="logo"
        />
        <Typography
          color="gray"
          variant="h3"
          fontWeight="500"
          fontSize="20px"
          style={{ textAlign: "center", marginTop: "30px" }}
          sx={{
            mt: 1,
          }}
        >
          Please check your inbox and spam as well to verify your email.
        </Typography>
      </Box>
      {/* <img src={Icon} alt="" /> */}
    </>
    // <div>
    // </div>
  );
};

export default index;
