import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import { CircularProgress, Container, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {
  sendingInvite,
  getMembers,
  getAllUser,
} from "../../../app/auth/auth.action";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const styles = {
  container: {
    borderRadius: "6px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 12px",
    gap: "8px",
    height: "40px",
    width: "158px",
    backgroundColor: "rgba(6, 96, 234, 0.07999999821186066)",
  },
  text: {
    textAlign: "left",
    verticalAlign: "top",
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "24%",
    color: "#0760ea",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    pt: "24px",
    pb: "24px",
    pr: "24px",
    pl: "24px",
    borderRadius: "12px",
    border: "0.50px #E2E8F0 solid",
  },
};

const BasicTable = ({
  loading,
  sendingInvite,
  getMembers,
  organizationId,
  members,
  getAllUser,
  totalUser,
  count,
}) => {
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };
  const handleChange = (event) => {
    setRole(event.target.value);
  };
  const submit = () => {
    sendingInvite(email, role);
  };
  React.useEffect(() => {
    getAllUser();
  }, []);
  console.log(totalUser);

  return (
    <Container>
      <Box sx={{ mt: 4, float: "right" }}>
        <Stack direction="row" spacing={1}>
          <Typography variant="h6">Total Users:</Typography>
          <Chip label={count} color="success" />
        </Stack>
      </Box>

      <Grid mb={2} container>
        <Grid item xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>status</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {totalUser?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      {row.fullName ? "Joined" : "not Joined"}
                    </TableCell>
                    <TableCell>{row.Role}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <br></br>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationId: state.auth.organizationId,
    members: state.auth.members,
    totalUser: state.auth.totalUser,
    count: state.auth.totalcount,
  };
};
const mapDispatchToProps = (dispatch) => ({
  sendingInvite: (email, role) => {
    dispatch(sendingInvite(email, role));
  },
  getMembers: (organizationId) => {
    dispatch(getMembers(organizationId));
  },
  getAllUser: () => {
    dispatch(getAllUser());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicTable);
