import React, { useState, useEffect } from "react";
import Settings from "./settings";
import Teams from "./teams";
import Upload from "./upload";
import Prompts from "./prompts";
import Users from "./all-members";
import LeftPane from "./left-pane";
import Panel from "./panel";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import { getUser } from "../../app/auth/auth.action";
const ChatBoardPage = ({ role }) => {
  const [activePage, setActivePage] = useState(1);
  const allPages = [Panel, Settings];

  console.log(role);
  function onPageChanged(idx) {
    setActivePage(idx);
  }

  useEffect(() => {
    getUser();
  }, [role]);

  return (
    <Box className="h-screen flex">
      <LeftPane role={role} onUpdateActive={onPageChanged} />
      {activePage && activePage === 1 && <Settings />}
      {activePage && activePage === 2 && <Upload />}
      {activePage && activePage === 3 && <Teams />}
      {activePage && activePage === 4 && <Users />}
    </Box>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    role: state.auth.role,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getUser: () => {
    dispatch(getUser());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBoardPage);
