export const GMAIL_ACTIONS = {
  ADDING_USER: "ADDING_USER",
  ADDED_USER: "ADDED_USER",
  ADDED_USER_FAILED: "ADDED_USER_FAILED",
  FETCHING_EMAILS: "FETCHING_EMAILS",
  FETCHED_EMAILS: "FETCHED_EMAILS",
  FETCHED_EMAILS_FAILED: "FETCHED_EMAILS_FAILED",
};

export const GMAIL_END_POINT = {
  AUTHORIZATION: "gmail/addNewUser",
  GETEMAILS: "gmail/getAllEmails",
};
