import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import theme from "../../util/theme";
import TextField from "../common/TextField/TextField";
import Dropdown from "../common/Dropdown/Dropdown";
import Button from "../common/Button/Button";
import Swal from "sweetalert2";
const HtmlToReact = require("html-to-react").Parser;

const CandidateResult = ({ close, result }) => {
  console.log("result", result);
  const htmlToReactParser = new HtmlToReact();

  const styles = {
    container: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      "& > *": {
        margin: "10px !important",
      },
    },
    heading: {
      fontSize: 40,
      textAlign: "center",
      margin: "auto",
      marginTop: "-30px",
      color: theme.palette.primary.main,
    },
    resultsContainer: {
      width: "100%",
      height: "300px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "& > *": {
        margin: "10px !important",
      },
      overflow: "auto",
      backgroundColor: theme.palette.secondary.main,
      border: `0.1px solid `,
      borderRadius: 2,
      borderColor: theme.palette.primary.main,
      padding: "12px",
    },

    actionsContainer: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "50%",
    },
  };
  const Result = ({result}) => {
    return (
      <>
        {
          result.map((row) => (
            <>
              <p><strong>Candidate Name:</strong>{row['Full Name']}</p>
              <p style={{ cursor: 'pointer' }} onClick={() => { window.open(row['LinkedIn Profile']) }} ><strong>LinkedIn Profile:</strong>{row['LinkedIn Profile']}</p>
              <p><strong>Location:</strong>{row['Location']}</p>
              <p><strong>Role:</strong>{row['Role']}</p>
              <p><strong>Expertise:</strong>{row['Skills'].join(',')}</p>
              <p><strong>Years Of Experience:</strong>{row['Years Of Experence']} Years</p>
              <p><strong>Projects:</strong></p>
              {
                row['Projects'].filter((pj)=> pj.Company && pj.Description && pj.Title).map((proj) => (
                  <>
                  <p><strong>Company:</strong>{proj['Company']}</p>
                  <p><strong>Description:</strong>{proj['Description']}</p>
                  <p><strong>Title:</strong>{proj['Title']}</p>
                  </>
                ))
              }
            </>
          ))
        }
      </>
    )
  }

  async function copyResults(){
    let resultStr = ``;
    for(const candids of result){
      resultStr+=`
      Candidate Name: ${candids['Full Name']}
      LinkedIn Profile: ${candids['LinkedIn Profile']}
      Location: ${candids['Location']}
      Role: ${candids['Role']}
      Expertise: ${candids['Skills'].join(',')}
      Years Of Experience: ${candids['Years Of Experence']} Years
      Projects: 
        ${candids['Projects'].filter((pj)=> pj.Company && pj.Description && pj.Title).map((prj)=>`
        - Company : ${prj.Company}
        - Description : ${prj.Description}
        - Title : ${prj.Title}
        `).join('\n')}
      `
    }
    await navigator.clipboard.writeText(resultStr);
    Swal.fire({
      title: 'Candidates Copied!',
      showConfirmButton:false,
      showCancelButton: false,
      showDenyButton: false,
      timer:1500
    })
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h4" sx={styles.heading}>
        Find <span>{"top candidates"}</span>
      </Typography>

      <Box sx={styles.resultsContainer}>
        <Result result={result} />
      </Box>

      <Box sx={styles.actionsContainer}>
      <Button onClick={()=>copyResults()} variant="contained" color="primary" sx={{ padding: '16px 100px' }}>
        Copy
      </Button>
      <Button variant="contained" color="primary" onClick={close}>
        Enter new job description
      </Button>
      </Box>

      <Typography
        variant="caption"
        sx={{
          fontSize: "0.6rem",
          color: theme.palette.primary.main,
          textAlign: "center",
        }}
      >
        Heads up: We identify candidates using job descriptions, skills, and any
        other data you input. Though our AI offers insightful starting points,
        <br />
        the blend of human discernment and thorough screening is indispensable.
        Please proceed with thoughtful diligence.
      </Typography>
    </Box>
  );
};

export default CandidateResult;
