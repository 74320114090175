import { makeRequest } from "../shared/request-service";
import { METHODS } from "../shared/requests.constants";
import { AUTH_END_POINT } from "./action.constant";

export const checkAutoLogin = () => {
  const jwt = localStorage.getItem("token");
  if (!jwt) return false;
  const decodedJwt = JSON.parse(atob(jwt.split(".")[1]));
  if (decodedJwt.expiry * 1000 < Date.now()) return false;
  return true;
};

export const loginUser = async (payload) => {
  try {
    const response = await makeRequest(
      AUTH_END_POINT.LOGIN,
      METHODS.POST,
      payload
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }

    if (!response) {
      throw new Error(`Something wen't wrong during logging in!`);
    }
    localStorage.setItem("token", response.data.accessToken);
    localStorage.setItem("refreshToken", response.data.accessToken);
    return response.data;
  } catch (error) {
    console.log(`loginUser >`, error);
    throw error;
  }
};

export const registerUser = async (payload) => {
  try {
    const response = await makeRequest(
      AUTH_END_POINT.REGISTER,
      METHODS.POST,
      payload
    );
    console.log(response);
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};
export const getAllUsers = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_END_POINT.ALL,
      METHODS.GET,
      {},
      { Authorization: `Bearer ${token}` }
    );
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};
export const queryStatus = async (array) => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_END_POINT.STATUS,
      METHODS.POST,
      { array },
      { Authorization: `Bearer ${token}` }
    );
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};
export const removeSubscription = async (subscriptionId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_END_POINT.CANCEL,
      METHODS.POST,
      { subscriptionId },
      { Authorization: `Bearer ${token}` }
    );
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};
export const verifyUser = async () => {
  try {
    const response = await makeRequest(AUTH_END_POINT.VERFIY, METHODS.POST, {});
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }

    localStorage.removeItem("token");

    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};

export const inviteTeam = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_END_POINT.SENDINVITE,
      METHODS.POST,
      payload,
      { Authorization: `Bearer ${token}` }
    );
    console.log(response);
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};

export const fetchUser = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_END_POINT.USER,
      METHODS.GET,
      {},
      { Authorization: `Bearer ${token}` }
    );
    if (!response) {
      throw new Error(`Something wen't wrong during Fetching!`);
    }
    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};

export const stripeSession = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_END_POINT.PLAN,
      METHODS.POST,
      {},
      { Authorization: `Bearer ${token}` }
    );
    if (!response) {
      throw new Error(`Something wen't wrong during Fetching!`);
    }
    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};

export const organizationMember = async (organizationId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_END_POINT.GET_MEMBERS + `/${organizationId}`,
      METHODS.GET,
      {},
      { Authorization: `Bearer ${token}` }
    );
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};

export const query = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_END_POINT.CREAETQUERY,
      METHODS.POST,
      payload,
      { Authorization: `Bearer ${token}` }
    );
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};

export const userQuery = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_END_POINT.USERQUERY,
      METHODS.POST,
      payload,
      { Authorization: `Bearer ${token}` }
    );
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};

export const getAllResponse = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await makeRequest(
      AUTH_END_POINT.GETALLRESPONSE,
      METHODS.GET,
      {},
      { Authorization: `Bearer ${token}` }
    );
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};
export const joinMember = async (payload) => {
  try {
    const response = await makeRequest(
      AUTH_END_POINT.JOINMEMBER,
      METHODS.POST,
      payload,
      { Authorization: `Bearer ${payload.token}` }
    );
    if (!response) {
      throw new Error(`Something wen't wrong !`);
    }
    return response.data;
  } catch (error) {
    console.log(`registerUser > `, error.response);
    throw error;
  }
};

// Csrf

export const csrf = async () => {
  try {
    const response = await makeRequest(AUTH_END_POINT.CSRF, METHODS.GET);
    console.log("Response: ", response);
    if (!response) {
      throw new Error(`Something wen't wrong during CSRF!`);
    }
    return response.headers["x-csrf-token"];
  } catch (error) {
    console.log(`CSRF > `, error.response);
    throw error;
  }
};
