import { AUTH_ACTIONS } from "./action.constant";

import * as authService from "./auth.service";

export const login = (email, password, navigate) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.LOGIN_USER });
    try {
      const payload = { email, password };
      const data = await authService.loginUser(payload);

      await navigate("/dashboard");
      return dispatch({ type: AUTH_ACTIONS.LOGGEDIN_USER, payload: data });
    } catch (error) {
      return dispatch({
        type: AUTH_ACTIONS.LOGIN_USER_FAILED,
        error: error.message,
      });
    }
  };
};

export const register = (fullName, email, password, navigate) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.REGISTERING_USER });
    try {
      const payload = {
        fullName,
        email,
        password,
      };
      await authService.registerUser(payload);
      await navigate("/admin/verifyemail");
      return dispatch({ type: AUTH_ACTIONS.REGISTERED_USER });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.REGISTERING_USER_FAILED,
        error: error,
      });
    }
  };
};
export const verifyEmail = (token, navigate) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.VERIFYING_EMAIL });
    try {
      await localStorage.removeItem("token");
      await localStorage.setItem("token", token);
      await authService.verifyUser();
      await navigate("/member/login");
      return dispatch({ type: AUTH_ACTIONS.VERIFIED_EMAIL });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.VERIFIED_EMAIL_FAILED,
        error: error,
      });
    }
  };
};
export const cancelSubscription = (subscriptionId) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.CANCELING_SUBSCRIPTION });
    try {
      const data = await authService.removeSubscription(subscriptionId);
      // console.log(data);
      window.location.href = data;
      return dispatch({ type: AUTH_ACTIONS.CANCEL_SUBSCRIPTION });
    } catch (error) {
      console.error(error);
      return dispatch({
        type: AUTH_ACTIONS.CANCEL_SUBSCRIPTION_FAILED,
        error: error,
      });
    }
  };
};

export const getUser = () => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.FETCHING_USER });
    try {
      const data = await authService.fetchUser();
      return dispatch({ type: AUTH_ACTIONS.FETCHED_USER, payload: data });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.FETCHING_USER_FAILED,
        error: error,
      });
    }
  };
};

export const createSession = () => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.CREATING_SESSION });
    try {
      const data = await authService.stripeSession();
      return dispatch({ type: AUTH_ACTIONS.CREATED_SESSION, payload: data });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.CREATED_SESSION_FAILED,
        error: error,
      });
    }
  };
};

export const getAllUser = () => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.GETTING_ALL_USERS });
    try {
      const data = await authService.getAllUsers();
      return dispatch({ type: AUTH_ACTIONS.GET_ALL_USERS, payload: data });
    } catch (error) {
      return dispatch({
        type: AUTH_ACTIONS.GET_ALL_USERS_FAILED,
        error: error,
      });
    }
  };
};
export const getStatus = (array) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.GETTING_STATUS });
    try {
      const data = await authService.queryStatus(array);
      return dispatch({ type: AUTH_ACTIONS.GET_STATUS, payload: data });
    } catch (error) {
      return dispatch({
        type: AUTH_ACTIONS.GET_STATUS_FAILED,
        error: error,
      });
    }
  };
};

export const userLogin = (email, password, navigate) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.LOGIN_USER });
    try {
      const payload = {
        email,
        password,
      };
      const data = await authService.loginUser(payload);

      // const data = await authService.queryStatus(array);
      localStorage.setItem("isLoggedIn", true);
      navigate("/memberDashboard");
      return dispatch({ type: AUTH_ACTIONS.LOGGEDIN_USER, payload: data });
    } catch (error) {
      return dispatch({
        type: AUTH_ACTIONS.LOGIN_USER_FAILED,
        error: error,
      });
    }
  };
};

export const sendingInvite = (email, role) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.SENDING_INVITE });
    try {
      const payload = {
        email,
        role,
      };
      await authService.inviteTeam(payload);
      return dispatch({ type: AUTH_ACTIONS.SEND_INVITE });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.SENDING_INVITE_FAILED,
        error: error,
      });
    }
  };
};
export const joinMember = (fullName, password, token, navigate) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.JOINING_MEMBER });
    try {
      const payload = {
        fullName,
        password,
        token,
      };
      await authService.joinMember(payload);
      localStorage.setItem("token", token);
      await navigate("/");

      return dispatch({ type: AUTH_ACTIONS.JOINED_MEMBER });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.JOINING_MEMBER_FAILED,
        error: error,
      });
    }
  };
};

export const getMembers = (organizationId) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.GETTING_MEMBERS });
    try {
      const data = await authService.organizationMember(organizationId);
      return dispatch({ type: AUTH_ACTIONS.GET_MEMBERS, payload: data });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.GETTING_MEMBERS_FAILED,
        error: error,
      });
    }
  };
};

export const createQuery = (payload) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.CREATING_QUERY });
    try {
      const data = await authService.query(payload);
      return dispatch({ type: AUTH_ACTIONS.CREATED_QUERY, payload: data });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.CREATING_QUERY_FAILED,
        error: error,
      });
    }
  };
};

export const userQuery = (payload) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.USER_QUERYING });
    try {
      const data = await authService.userQuery(payload);
      return dispatch({ type: AUTH_ACTIONS.USER_QUERY, payload: data });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.USER_QUERY_FAILED,
        error: error,
      });
    }
  };
};

export const GetAllQuery = () => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.GETTING_ALL_RESPONSES });
    try {
      const data = await authService.getAllResponse();
      return dispatch({ type: AUTH_ACTIONS.GET_ALL_RESPONSES, payload: data });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.GET_ALL_RESPONSES_FAILED,
        error: error,
      });
    }
  };
};

// taken csrf
export const csrf = () => {
  return async (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.TAKING_CSRF });
    try {
      const token = await authService.csrf();

      console.log("Token: ", token);
      return dispatch({
        type: AUTH_ACTIONS.TAKEN_CSRF,
        payload: token,
      });
    } catch (error) {
      console.error(error);

      return dispatch({
        type: AUTH_ACTIONS.CSRF_FAILED,
        error: error,
      });
    }
  };
};
