import { Box, Typography } from "@mui/material";
import Logo from "../../assests/logo.svg";
import Button from "../common/Button/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function Header({
  removeSubscription,
  subscriptionId,
  loging,
  logout,
  session,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const move = () => {
    const token = localStorage.getItem("token");
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (token && isLoggedIn) {
      navigate("/memberDashboard");
    } else {
      navigate("/member/login");
    }
  };
  const styles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
    },
    logo: {
      width: "40px",
      height: "40px",
    },
    logoContainer: {
      display: "flex",
      padding: "10px",
      justifyContent: "start",
      alignItems: "center",
      "& > *": {
        margin: "10px",
      },
    },
  };
  return (
    <>
   {
    isMediumScreen ? 
    <Box sx={{ display: 'flex'}}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" open={open} sx={{backgroundColor:"#fff" , boxShadow:"none"}}>
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
          <Link to={"/"}>
        <Box sx={styles.logoContainer}>
          <img style={styles.logo} src={Logo} alt="logo" />
          <Typography variant="h5" sx={{color:"#000 !important"}}>Forecruiters</Typography>
        </Box>
      </Link>
          </Typography>
          <IconButton
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' }) , color:"#000 important" }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        {loging ? (
        <Box sx={{display:"flex" , flexDirection:"column" , alignItems:"start" }}  >
          {subscriptionId ? (
            <Button variant="text" onClick={removeSubscription}>
              Billing
            </Button>
          ) : (
            <Button variant="text" onClick={session}>
              Update plan
            </Button>
          )}
          <Button variant="text" onClick={logout}>
            Logout
          </Button>

          {/* <Button variant="outlined">Find the best candidates</Button> */}
        </Box>
      ) : (
        <Box sx={{display:"flex" , flexDirection:"column" , alignItems:"start"}}  >
          <Button variant="text">How it works</Button>
          <Button variant="text">pricing</Button>
          <Link to={"/member/login"}>
            <Button variant="text">login</Button>
          </Link>
          <Link to={"/member/register"}>
            <Button variant="text">sign up</Button>
          </Link>
          <Button onClick={move} variant="outlined">
            Find the best candidates
          </Button>
        </Box>
      )}
        </List>
        <Divider />
      </Drawer>
    </Box>
    :
    <Box sx={styles.container}>
      <Link to={"/"}>
        <Box sx={styles.logoContainer}>
          <img style={styles.logo} src={Logo} alt="logo" />
          <Typography variant="h5">Forecruiters</Typography>
        </Box>
      </Link>
      {loging ? (
        <Box sx={{display:"flex" , alignItems : "center"}} >
          {subscriptionId ? (
            <Button variant="text" onClick={removeSubscription}>
              Billing
            </Button>
          ) : (
            <Button variant="text" onClick={session}>
              Update plan
            </Button>
          )}
          <Button variant="text" onClick={logout}>
            Logout
          </Button>

          <Button variant="outlined">Find the best candidates</Button>
        </Box>
      ) : (
        <Box sx={{display:"flex" , alignItems : "center"}}  >
          <Button variant="text">How it works</Button>
          <Button variant="text">pricing</Button>
          <Link to={"/member/login"}>
            <Button variant="text">login</Button>
          </Link>
          <Link to={"/member/register"}>
            <Button variant="text">sign up</Button>
          </Link>
          <Button onClick={move} variant="outlined">
            Find the best candidates
          </Button>
        </Box>
      )}
    </Box>
   } 
    </>
  );
}
