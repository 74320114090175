import { Box, CircularProgress, Grid, Typography, Button } from "@mui/material";
import theme from "../../util/theme";
import TextField from "../common/TextField/TextField";
import Dropdown from "../common/Dropdown/Dropdown";
import CustomButton from "../common/Button/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CandidateResult from "../CandidateResult/CandidateResult";
import { connect } from "react-redux";
import {
  userQuery,
  createSession,
  cancelSubscription,
  getUser,
} from "../../app/auth/auth.action";
import CircleLoader from "react-spinners/PulseLoader";
import { blue, blueGrey, green } from "@mui/material/colors";
import Header from "../Header/Header";
import Swal from "sweetalert2";

const CandidateSearch = ({
  userQuery,
  result,
  loading,
  paymentStatus,
  createSession,
  url,
  subscriptionId,
  cancelSubscription,
  getUser,
  customerId,
  fullName,
  queryCount,
}) => {
  const loggedIn = localStorage.getItem("isLoggedIn");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const [key, setKey] = React.useState("");
  const [queryResult, setQueryResult] = React.useState(null);
  const [location, setLocation] = React.useState("");
  const [experience, setExperience] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [extraInfo, setextraInfo] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [description, setDescription] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLocation("");
    setTitle("");
    setDescription("");
    setExperience("");
    setGender("");
  };

  React.useEffect(() => {
    getUser();
  }, []);

  React.useEffect(() => {
    if (result?.length > 0) {
      setQueryResult(result);
      setOpen(true);
    }
  }, [result]);
  React.useEffect(() => {
    if (fullName && !paymentStatus && queryCount >= 3) {
      Swal.fire({
        title: "you are on free trial,please upgrade your plan ",
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Update",
      }).then((result) => {
        if (result.isConfirmed) {
          createSession();
        }
      });
    } else if (paymentStatus) {
      Swal.close();
    }
  }, [paymentStatus, fullName]);

  const removeSubscription = () => {
    cancelSubscription(customerId);
  };

  const navigate = useNavigate();
  const styles = {
    container: {
      position: "relative",
      marginTop: "105px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      "& > *": {
        margin: "10px !important",
      },
    },
    heading: {
      fontSize: 40,
      textAlign: "center",
      margin: "auto",
      marginTop: "20px",
      color: theme.palette.primary.main,
    },
    inputsContainer: {
      width: "50%",
      display: "flex",
      justifyContent: "space-between",
      "& > *": {
        margin: "10px !important",
      },
    },
    experienceInput: {
      height: "500px",
    },
    actionsContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "50%",
    },
  };
  const logout = () => {
    localStorage.removeItem("isLoggedIn");
    navigate("/member/login");
  };

  const sessionCreate = () => {
    createSession();
  };
  const creatUserQuery = () => {
    if (fullName && !paymentStatus && queryCount >= 3) {
      Swal.fire({
        title: "you are on free trial,please upgrade your plan ",
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Update",
      }).then((result) => {
        if (result.isConfirmed) {
          createSession();
        }
      });
    } else {
      const payload = {
        location,
        experience,
        title,
        description,
        extraInfo,
        gender,
      };
      userQuery(payload);
    }
  };

  React.useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CandidateResult result={queryResult} close={handleClose} />
        </Box>
      </Modal>
      <Header
        subscriptionId={subscriptionId}
        loging={loggedIn}
        logout={logout}
        session={sessionCreate}
        removeSubscription={removeSubscription}
      />
      <Box sx={styles.container}>
        <Typography variant="h4" sx={styles.heading}>
          Find <span>{"top candidates"}</span>
        </Typography>

      <Grid container spacing={2} sx={{justifyContent:"center"}}>
        <Grid item xs={12} md={3} sx={{padding:"10px !important"}}>
        <TextField
            onChange={(e) => setLocation(e.target.value)}
            value={location}
            label="Add location"
            variant="outlined"
            placeholder="Add locationnn"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{padding:"10px !important"}} >
        <TextField
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            label="Job Role"
            variant="outlined"
            placeholder="Job Role"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{padding:"10px !important"}} >
        <TextField
            onChange={(e) => setExperience(e.target.value)}
            value={experience}
            label="Add experience"
            variant="outlined"
            placeholder="Add experience"
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{justifyContent:"center"}} >
        <Grid item xs={12} md={9} sx={{padding:"10px !important" , margin:"10px"}} >
        <TextField
            label="Job Description"
            variant="outlined"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder="Add Job Description"
            fullWidth
            multiline
            rows={5}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{justifyContent:"center"}}>
        <Grid item xs={12} md={9} sx={{padding:"10px !important" }}>
        <TextField
              onChange={(e) => setextraInfo(e.target.value)}
              value={extraInfo}
              label="Add Extra Info"
              variant="outlined"
              placeholder="Add experience"
              fullWidth
            />
        </Grid>
      </Grid>

        <Grid container spacing={2} sx={{justifyContent:"center"}} >
        <Grid item xs={12} md={9} sx={{display:"flex" , justifyContent:"space-between" , margin:"10px" }} >
          <CustomButton variant="contained" color="error">
            Cancel
          </CustomButton>

          {loading? (
            <Box sx={{margin:"0 10px"}} >
              <p>
                Sit back and relax while AI is finding you the best matches...
              </p>
              <CircularProgress
                size={30}
                sx={{
                  color: blue[200],
                }}
              />
            </Box>
          ) : (
            <CustomButton
              onClick={creatUserQuery}
              variant="contained"
              color="primary"
            >
              Submit
            </CustomButton>
          )}
        </Grid>
        </Grid>       
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    result: state.auth.realQuery,
    loading: state.auth.loading,
    paymentStatus: state.auth.paymentStatus,
    fullName: state.auth.fullName,
    url: state.auth.url,
    subscriptionId: state.auth.subscriptionId,
    customerId: state.auth.customerId,
    queryCount: state.auth.queryCount,
  };
};
const mapDispatchToProps = (dispatch) => ({
  userQuery: (payload) => {
    dispatch(userQuery(payload));
  },
  createSession: () => {
    dispatch(createSession());
  },
  getUser: () => {
    dispatch(getUser());
  },
  cancelSubscription: (subscriptionId) => {
    dispatch(cancelSubscription(subscriptionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSearch);

// export default CandidateSearch;
