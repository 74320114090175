import React from "react";
import { useRoutes } from "react-router-dom";
import Router from "./routes/router";
import { checkAutoLogin } from "./app/auth/auth.service";

const App = () => {
  const value = localStorage.getItem("isLoggedIn");
  console.log(value);
  const routing = useRoutes(Router(checkAutoLogin(), value));
  return <div>{routing}</div>;
};

export default App;
