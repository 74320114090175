import React, { useEffect } from "react";
import image from "../../assests/YbIni.png";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/memberDashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // or any other height as required
      }}
    >
      <img src={image} alt="Descriptive text" />
    </div>
  );
};

export default Index;
