import React from "react";
import { Navigate } from "react-router-dom";
import Login from "../components/sign-in/sign-in";
import Signup from "../components/sign-up/sign-up";
import Verification from "../components/verification";
import ChatBoardPage from "../pages/ChatBoardPage";
import BlankLayout from "../components/blank-layout/BlankLayout";
import VerifyEmail from "../components/verfication";
import Loader from "../components/loader";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import MemberLogin from "../components/sigin-member/sign-in";
import Candidate from "../components/CandidateSearch/CandidateSearch";
import Success from "../components/success/index";
import CandidateSearch from "../components/CandidateSearch/CandidateSearch";

const Router = (isLoggedIn, userLoggedIn) => [
  {
    path: "/",
    element: (
      <>
        <Header />
        <Hero />
      </>
    ),
  },

  {
    path: "/dashboard",
    element: isLoggedIn ? <ChatBoardPage /> : <Login />,
  },

  {
    path: "member",
    element: <BlankLayout />,
    children: [
      {
        path: "login",
        element: (
          <>
            <Header />
            <MemberLogin />
          </>
        ),
      },
      {
        path: "register",
        element: (
          <>
            <Header />
            <Signup />
          </>
        ),
      },
      { path: "verifyemail", element: <VerifyEmail /> },
      { path: "verify/:token", element: <Loader /> },
      { path: "member-verify/:token", element: <Verification /> },
      { path: "success", element: <Success /> },
    ],
  },

  {
    path: "memberDashboard",
    element: userLoggedIn ? <Candidate /> : <Navigate to="/member/login" />,
    children: [
      { path: "register", element: <Signup /> },
      { path: "verifyemail", element: <VerifyEmail /> },
      { path: "verify/:token", element: <Loader /> },
      { path: "member-verify/:token", element: <Verification /> },
    ],
  },
  {
    path: "admin",
    element: <BlankLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "register", element: <Signup /> },
      { path: "verifyemail", element: <VerifyEmail /> },
      { path: "verify/:token", element: <Loader /> },
      { path: "member-verify/:token", element: <Verification /> },
    ],
  },
];

export default Router;
