import { ButtonBase, Button as MuiButton } from "@mui/material";
import React from "react";
import theme from "../../../util/theme";

// interface Props {
//   children: React.ReactNode;
//   onClick?: () => void;
//   variant?: "outlined" | "contained" | "text";
//   color?: "primary" | "secondary" | "info" | "success" | "warning" | "error";
// }

export default function Button({
  children,
  onClick,
  variant,
  color = "primary",
}) {
  const style = {
    border: variant === "outlined" ? 2 : 0,
    color: variant === "text" && "#979797",
    padding: 2,
    fontWeight: 700,
    borderRadius: 2,
    backgroundColor:
      variant === "contained" ? theme.palette[color].main : "transparent", // use color from props if variant is contained
    "&:hover": {
      backgroundColor:
        variant === "contained" ? theme.palette[color].dark : "transparent", // darken background on hover for contained buttons
      // Fix hover effect for outlined variant if needed:
      // If you need a hover effect for the 'outlined' variant, add it accordingly
    },
  };
  return (
    <MuiButton onClick={onClick} sx={style} variant={variant} color={color}>
      {children}
    </MuiButton>
  );
}
