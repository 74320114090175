import { makeRequest } from "../shared/request-service";
import { METHODS } from "../shared/requests.constants";
import { GMAIL_END_POINT } from "./gmail.constant";

export const checkAutoLogin = () => {
  const jwt = localStorage.getItem("token");
  if (!jwt) return false;
  const decodedJwt = JSON.parse(atob(jwt.split(".")[1]));
  if (decodedJwt.expiry * 1000 < Date.now()) return false;
  return true;
};

export const AddingUser = async (payload) => {
  try {
    console.log(payload);
    const response = await makeRequest(
      GMAIL_END_POINT.AUTHORIZATION,
      METHODS.POST,
      { payload },
      {}
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }

    if (!response) {
      throw new Error(`Something wen't wrong during logging in!`);
    }
  } catch (error) {
    console.log(`loginUser >`, error);
    throw error;
  }
};
export const getAllEmailsSerivce = async () => {
  try {
    const response = await makeRequest(
      GMAIL_END_POINT.GETEMAILS,
      METHODS.GET,
      {},
      {}
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }

    if (!response) {
      throw new Error(`Something wen't wrong during logging in!`);
    }

    return response.data;
  } catch (error) {
    console.log(`loginUser >`, error);
    throw error;
  }
};
