import { combineReducers } from "redux";
import auth from "../app/auth/auth.reducer";
import { gmailReducer } from "../app/gmail/gmail.reducer";

const rootReducer = combineReducers({
  auth,
  gmailReducer,
});

export default rootReducer;
