import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { connect } from "react-redux";
import { register } from "../../app/auth/auth.action";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { blue, blueGrey, green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { joinMember } from "../../app/auth/auth.action";

const defaultTheme = createTheme();
const SignUp = ({ register, loading, joinMember }) => {
  const params = useParams();
  const { token } = params;
  const [password, setPassword] = React.useState("");
  const [name, setName] = React.useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    joinMember(name, password, token, navigate);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}></Avatar>
          <Typography component="h1" variant="h5">
            Join Us
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="fullName"
              label="Full Name"
              name="fullName"
              autoComplete="fullName"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: "30px" }}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: blueGrey[100],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                "Join Now"
              )}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
const mapStateToProps = (state) => {
  return {
    firstName: state.auth,
    email: state.auth.email,
    loading: state.auth.loading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  joinMember: (fullName, password, token, navigate) => {
    dispatch(joinMember(fullName, password, token, navigate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
