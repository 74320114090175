import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBaseApi } from "../../../config/Enviroment";

const Index = () => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const token = localStorage.getItem("token");
      const data = axios
        .post(`${getBaseApi()}/user/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          toast.success("your file has been procecsd");
        });
    }
  }, [file]);

  const handleChange = (file) => {
    setFile(file);
  };
  const fileTypes = ["csv"];
  return (
    <div style={{ display: "flex", justifyContent: "Center", margin: "auto" }}>
      <ToastContainer />
      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
    </div>
  );
};

export default Index;
