import React from "react";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import { CircularProgress, Container } from "@mui/material";
import { grey } from "@mui/material/colors";
const HtmlToReact = require("html-to-react").Parser;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  height: 650,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  overflowX: "hidden",
};

const Index = ({ queries }) => {
  const htmlToReactParser = new HtmlToReact();
  const [open, setOpen] = useState(false);
  const [responses, setResponses] = useState("");

  const handleOpen = (item) => {
    console.log(item);
    setOpen(true);
    setResponses(item.response);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {" "}
      <Modal
        open={open}
        // style={style}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {!responses ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress
                  size={200}
                  sx={{
                    color: grey[500],
                  }}
                />
              </div>
            ) : (
              htmlToReactParser.parse(responses)
            )}
          </Typography>

          <Button
            onClick={handleClose}
            variant="contained"
            style={{
              background: "red",
              float: "left",
              display: responses ? "block" : "none",
            }}
          >
            cancel
          </Button>
          <br></br>
        </Box>
      </Modal>
      <Card sx={{ marginBottom: "12rem", cursor: "pointer" }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {queries?.map((item, index) => (
              <Grid item xs={4} md={4}>
                <Card
                  onClick={() => handleOpen(item)}
                  style={{ backgroundColor: "#f6f6f6" }}
                >
                  <CardContent>
                    <Typography sx={{ fontSize: 16 }} gutterBottom>
                      <b>Query:</b> {item.query}
                    </Typography>
                    <Typography sx={{ fontSize: 16 }} gutterBottom>
                      <b>Status:&nbsp;&nbsp;</b>
                      <Chip label={item.status} color="success" />
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default Index;
