import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { verifyEmail } from "../../app/auth/auth.action";

const Index = ({ verifyEmail }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { token } = params;

  useEffect(() => {
    verifyEmail(token, navigate);
  }, []);
  return <div>Token Verify</div>;
};

const mapStateToProps = (state) => {
  return {
    firstName: state.auth,
    email: state.auth.email,
    loading: state.auth.loading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  verifyEmail: (id, navigate) => {
    dispatch(verifyEmail(id, navigate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
