import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { connect } from "react-redux";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { AuthorizeEMail, getAllEmails } from "../../../app/gmail/gmail.action";
import { getUser, getStatus } from "../../../app/auth/auth.action";
import { getBaseApi } from "../../../config/Enviroment";
import CircleLoader from "react-spinners/PulseLoader";
import { FileUploader } from "react-drag-drop-files";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { CircularProgress, Container } from "@mui/material";
import { grey } from "@mui/material/colors";
import { createQuery, GetAllQuery } from "../../../app/auth/auth.action";
import QueryCard from "../cards/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 450,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};
const styles = {
  container: {
    borderRadius: "6px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 12px",
    gap: "8px",
    height: "40px",
    width: "158px",
    backgroundColor: "rgba(6, 96, 234, 0.07999999821186066)",
  },
  text: {
    textAlign: "left",
    verticalAlign: "top",
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "24%",
    color: "#0760ea",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    pt: "24px",
    pb: "24px",
    pr: "24px",
    pl: "24px",
    borderRadius: "12px",
    border: "0.50px #E2E8F0 solid",
  },
};

const fetchConfigs = () => {
  return axios.get(getBaseApi() + "configs");
};
const fileTypes = ["csv"];

const updateOpenAI = (key) => {
  return axios.post(getBaseApi() + "openai_creds", {
    openai_creds: key,
  });
};

const Settings = ({
  AuthorizeEMail,
  getAllEmails,
  success,
  loading,
  getStatus,
  emails,
  getUser,
  createQuery,
  queries,
  GetAllQuery,
  userQueries,
}) => {
  const [file, setFile] = useState(null);

  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [location, setLocation] = useState("");
  const [experience, setExperience] = useState("");
  const [title, setTitle] = useState("");
  const [extraInfo, setextraInfo] = useState("");
  const [gender, setGender] = useState("");
  const [description, setDescription] = useState("");
  const handleChange = (file) => {
    setFile(file);
  };

  const [open, setOpen] = useState(false);
  const [status, setStatusArray] = useState(false);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const token = localStorage.getItem("token");
      const data = axios.post(
        "http://localhost:4000/api/user/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  }, [file]);

  useEffect(() => {
    GetAllQuery();
  }, []);
  console.log(queries);

  const handleOpen = () => {
    setOpen(true);
  };

  const addUser = () => {
    // AuthorizeEMail(email);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (success) {
      setOpen(false);
    }
  }, [success]);

  const sendQeury = () => {
    const payload = {
      location,
      experience,
      title,
      description,
      extraInfo,
      gender,
    };
    createQuery(payload);
  };
  useEffect(() => {
    const array = [];
    if (queries) {
      for (const item of queries) {
        array.push(item.id);
      }

      setStatusArray(array);
    }
  }, [queries]);

  // console.log(status);
  useEffect(() => {
    const intervalId = setInterval(() => {
      getStatus(status);
    }, 20000);

    return () => {
      clearInterval(intervalId);
    };
  }, [status]);

  useEffect(() => {
    console.log("this is calling");
    for (let i = 0; i < queries?.length; i++) {
      for (let j = 0; j < userQueries?.length; j++) {
        if (queries[i].id === userQueries[j].id) {
          queries[i].status = userQueries[j].status;
          queries[i].response = userQueries[j].response;
        }
      }
    }
  }, [userQueries]);

  return (
    <>
      <Modal
        open={open}
        style={style}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add new Query
          </Typography>

          <Grid mb={2} container spacing={2}>
            <Grid item xs={4}>
              <InputLabel
                style={{ display: "none" }}
                htmlFor="outlined-adornment-amount"
              >
                Location
              </InputLabel>
              <FormControl fullWidth variant="filled">
                <OutlinedInput
                  id="email"
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                  variant="outlined"
                  fullWidth
                  required
                  type="email"
                  placeholder="Add Location"
                  style={{ borderRadius: "10px" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <InputLabel
                style={{ display: "none" }}
                htmlFor="outlined-adornment-amount"
              >
                Job Title
              </InputLabel>
              <FormControl fullWidth variant="filled">
                <OutlinedInput
                  id="email"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  variant="outlined"
                  fullWidth
                  required
                  type="text"
                  placeholder="Add job title e.g Software engineer"
                  style={{ borderRadius: "10px" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <InputLabel
                style={{ display: "none" }}
                htmlFor="outlined-adornment-amount"
              >
                experience
              </InputLabel>
              <FormControl fullWidth variant="filled">
                <OutlinedInput
                  id="email"
                  onChange={(e) => setExperience(e.target.value)}
                  value={experience}
                  variant="outlined"
                  fullWidth
                  required
                  type="text"
                  placeholder="Add experiene year e.g 1,2.."
                  style={{ borderRadius: "10px" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ borderRadius: "!2px" }}>
              <TextField
                sx={{ width: "100%" }}
                placeholder="Enter the description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                multiline
                rows={5}
                maxRows={6}
                style={{ borderRadius: "102px" }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  label="Role"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>female</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={8}>
              <InputLabel
                style={{ display: "none" }}
                htmlFor="outlined-adornment-amount"
              >
                Any extra info
              </InputLabel>
              <FormControl fullWidth variant="filled">
                <OutlinedInput
                  id="email"
                  onChange={(e) => setextraInfo(e.target.value)}
                  value={extraInfo}
                  variant="outlined"
                  fullWidth
                  required
                  type="text"
                  placeholder="Add any extra info.."
                  style={{ borderRadius: "10px" }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <br></br>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ background: "red", float: "left" }}
          >
            cancel
          </Button>

          <Button
            onClick={sendQeury}
            variant="contained"
            style={{ float: "right" }}
          >
            {loading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: grey[500],
                }}
              />
            ) : (
              "Add"
            )}
          </Button>
        </Box>
      </Modal>

      <main className="ml-0 h-full p-6 flex-grow">
        <Box
          sx={{
            mt: 3,
            maxHeight: "100rem",
            height: "35rem",
            overflow: "scroll",
            overflowX: "hidden",
            marginBottom: "10rem",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
          }}
        >
          <Button
            style={{ float: "right" }}
            onClick={handleOpen}
            variant="contained"
          >
            Add Query
          </Button>

          {/* <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          /> */}
          <QueryCard queries={queries} />
          {/* <Card sx={{ marginBottom: "12rem" }}>
            <CardContent>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {queries?.map((item, index) => (
                  <Grid item xs={4} md={4}>
                    <Card style={{ backgroundColor: "#f6f6f6" }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 16 }} gutterBottom>
                          <b>Query:</b> {item.query}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card> */}
        </Box>
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationId: state.auth.organizationId,
    email: state.auth.email,
    loading: state.auth.loading,
    success: state.auth.success,
    showAlert: state.auth.showAlert,
    emails: state.gmailReducer.emails,
    queries: state.auth.queries,
    userQueries: state.auth.userQueries,
  };
};
const mapDispatchToProps = (dispatch) => ({
  AuthorizeEMail: (email) => {
    dispatch(AuthorizeEMail(email));
  },

  getUser: () => {
    dispatch(getUser());
  },
  GetAllQuery: () => {
    dispatch(GetAllQuery());
  },

  createQuery: (payload) => {
    dispatch(createQuery(payload));
  },
  getStatus: (payload) => {
    dispatch(getStatus(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
